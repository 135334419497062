import axios from "axios";
import { SERVER_URL } from "@/helpers/utils/config_system.js"

const TOKENUSER = JSON.parse(localStorage.getItem("auth"));

export const BASE_URL = SERVER_URL;

export default axios.create({
  baseURL: BASE_URL + "/api",
  headers: {
    Authorization: `Bearer ${TOKENUSER}`,
  },
});
