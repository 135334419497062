const ARRROUTER = [
  {
    path: "/",
    name: "PortalDigital",
    meta: { title: "Trang chủ" },
    component: () => import("./views/map_portal/MapPortal.vue"),
  },
];

export default function (path) {
  return ARRROUTER.map((item) => {
    if (path) item.path = "/" + path + item.path;
    return item;
  });
}
